import { useLoadingState } from '~/composables/useLoadingState';

export const wpRestApiFetcher = async <T>(
  path: string,
  options?: Omit<Parameters<typeof fetch>[0], 'url'>,
): Promise<{
  data: T;
  total: number | null;
  totalPages: number | null;
}> => {
  const now = Date.now();
  const { addLoadingKey, removeLoadingKey } = useLoadingState();

  try {
    const {
      public: { mock, wpApiBaseUrl },
    } = useRuntimeConfig();

    addLoadingKey(`wpRestApiFetcher${now}`);

    const response = await fetch(`${wpApiBaseUrl}${path}`, {
      method: 'GET',
      ...options,
    });

    const _response = await response.json();

    const data = mock ? _response?.data : _response;

    const total = mock
      ? _response?.total
      : response?.headers?.get('X-WP-Total');

    const numberedTotal = total ? Number(total) : null;

    if (numberedTotal && isNaN(numberedTotal))
      throw createError('total is not a number');

    const totalPages = mock
      ? _response?.totalPages
      : response?.headers?.get('X-WP-TotalPages');

    const numberedTotalPages = totalPages ? Number(totalPages) : null;
    if (numberedTotalPages && isNaN(numberedTotalPages))
      throw createError('totalPages is not a number');

    return { data, total, totalPages };
  } catch (error: any) {
    throw await error.json();
  } finally {
    removeLoadingKey(`wpRestApiFetcher${now}`);
  }
};
