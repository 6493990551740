import { CompanyRepository } from '~/api/repositories/CompanyRepository';

/**
 * 企業周りのカスタムフック
 * MEMO: ただfetchしてるだけのフックなので、テストは書かない
 */
export const useCompany = async () => {
  const route = useRoute();
  // yupバリデかけようかな
  const companySlug = route.params.companySlug.toString();

  const { getCompany } = CompanyRepository();

  /**
   * 企業ID
   */
  const company = await getCompany(companySlug);

  return {
    company,
  };
};
