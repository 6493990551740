import { wpRestApiFetcher } from '~/libs/fetcher';
import type { Media } from '~/types/Media';

export const MediaRepository = () => {
  /**
   * メディアを取得する
   */
  const getMedia = async (mediaId: string): Promise<Media> => {
    const { data } = await wpRestApiFetcher<any>(`/media/${mediaId}`, {
      method: 'GET',
    });

    return {
      id: Number(data.id),
      title: data.title.rendered,
      url: data.source_url,
    };
  };

  /**
   * プレビュー記事に設定されているメディアを取得する
   */
  const getMediaForPreview = async (
    mediaId: string,
    token: string,
  ): Promise<Media> => {
    const { data } = await wpRestApiFetcher<any>(`/media/${mediaId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      id: Number(data.id),
      title: data.title.rendered,
      url: data.source_url,
    };
  };

  return {
    getMedia,
    getMediaForPreview,
  };
};
