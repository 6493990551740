/**
 * 企業情報に設定されるカラータイプ
 */
export const COLOR_TYPE = {
  Blue: 'blue',
  Orange: 'orange',
  Green: 'green',
  DarkGray: 'dark-gray',
  DarkRed: 'dark-red',
} as const;

export type ColorType = (typeof COLOR_TYPE)[keyof typeof COLOR_TYPE];
